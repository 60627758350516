import { useStaticQuery, graphql } from "gatsby"
import React from "react"
import { Grid, Row, Col } from 'react-bootstrap';
import footerStyle from '../styles/footer.module.scss'
import { FaCopyright } from 'react-icons/fa';

const Footer = () => {
  const data = useStaticQuery(graphql`
    query FooterTitleQuery {
      site {
        siteMetadata {
          legalName
        }
      }
    }
  `)

  return (
    <footer className={footerStyle.footer}>
      <Grid>
        <Row>
          <Col md={12}>
            <div className={footerStyle.copyright}>
              {data.site.siteMetadata.legalName}{` `}<FaCopyright/>{` `}{new Date().getFullYear()}. All rights reserved.<br/>Powered with{` `}
              <a href="https://www.gatsbyjs.com">GatsbyJS</a> |{` `}
              Code from{` `}
              <a href="https://www.railsagency.com">Rails Agency</a>
            </div>
          </Col>
        </Row>
      </Grid>
    </footer>
  )
}

export default Footer
