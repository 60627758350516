import { useStaticQuery, Link, graphql } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { Grid, Row, Col, Button } from 'react-bootstrap';
import headerStyle from '../styles/header.module.scss'
import { FaBars, FaTimes, FaPhone, FaUser, FaAddressBook } from 'react-icons/fa';
import scrollTo from 'gatsby-plugin-smoothscroll';
import Obfuscate from 'react-obfuscate';

const Header = ({ siteTitle }) => {
  const data = useStaticQuery(graphql`
    query HeaderTitleQuery {
      site {
        siteMetadata {
          name
          phoneNumber
        }
      }
    }
  `)

  function openMobileMenu(elem) {
    document.getElementsByClassName(elem)[0].style.transform = 'translateY(0)'
  }

  function closeMobileMenu(elem) {
    document.getElementsByClassName(elem)[0].style.transform = null
  }

  return (
    <div>
      <header className={headerStyle.header}>
          <Grid>
              <Row>
                  <Col md={3} xs={10}>
                      <div className={headerStyle.logo}>
                          {data.site.siteMetadata.name}
                      </div>
                  </Col>
                  <Col md={9} smHidden xsHidden>
                      <div className={headerStyle.mainMenu}>
                          <ul>
                              {/*
                                THIS IS THE WEB (MEDIUM AND LARGE VIEWPORTS) MENU
                                <li><a href="#offers">Offers</a></li>
                                <li><a href="#testimonials">Testimonials</a></li>
                              */}
                              <li><a onClick={() => scrollTo('#about')}>About</a></li>
                              <li><a onClick={() => scrollTo('#contact')}>Contact</a></li>
                              <li>

                                <Button className={headerStyle.btnBlue} bsSize="large">
                                  <Obfuscate obfuscateChildren={false} className={headerStyle.phoneNumber} tel={data.site.siteMetadata.phoneNumber}>
                                    Call Me
                                  </Obfuscate>
                                </Button>
                              </li>
                          </ul>
                      </div>
                  </Col>
                  <Col xs={2} mdHidden lgHidden className="text-right">
                      <div className={headerStyle.mobileBtn} onClick={(e) => openMobileMenu(headerStyle.mobileMenuWrapper)}>
                          <FaBars/>
                      </div>
                  </Col>
              </Row>
          </Grid>
      </header>
      <div className={`hidden-md hidden-lg ${headerStyle.mobileMenuWrapper}`}>
        <Grid>
          <Row>
            <Col md={12}>
              <div className={headerStyle.closeMobMenu} onClick={() => closeMobileMenu(headerStyle.mobileMenuWrapper)}>
                <FaTimes/>
              </div>
              <div className={headerStyle.mobileBoxWrap}>
                <div className={headerStyle.mobileBoxFlex}>
                  <div className={headerStyle.mobileMenu}>
                    <ul>
                      {/*
                        THIS IS THE MOBILE (SMALL AND EXTRA SMALL VIEWPORTS) MENU
                        <li><a href="#offers">Offers</a></li>
                        <li><a href="#testimonials">Testimonials</a></li>
                      */}
                      <li><a onClick={() => { closeMobileMenu(headerStyle.mobileMenuWrapper); scrollTo('#about')}}><FaUser/>&nbsp;About</a></li>
                      <li><a onClick={() => { closeMobileMenu(headerStyle.mobileMenuWrapper); scrollTo('#contact')}}><FaAddressBook/>&nbsp;Contact</a></li>
                      <li>
                        <Obfuscate obfuscateChildren={false} className={headerStyle.phoneNumber} tel={data.site.siteMetadata.phoneNumber}>
                          <FaPhone/>&nbsp;Call Me
                        </Obfuscate>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Grid>
      </div>
    </div>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
